import React from 'react'
import {  MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from 'mdb-react-ui-kit';

export default function BoardList({ listOfScores, scoreToConsider}) {
  return (
    <div>
      <MDBTable align='middle' hover>
        <MDBTableHead light>
          <tr >
            <th scope='col'>Ranking</th>
            <th scope='col'>Username</th>
            <th scope='col'>Score</th>
            <th scope='col'>Max Streak</th>
          </tr>
        </MDBTableHead>

        {
        listOfScores.map( (user, index) => (
          <MDBTableBody key={index}>
          <tr >
          <td>
             <p className='fw-normal mb-1'> {index +1}</p>
            </td>
            <td>
              <div className='align-items-center'>
                <div className='ms-3'>
                  <p className='fw-bold mb-1'>{user.username}</p>
                </div>
              </div>
            </td>

            <td>
             <p className='fw-normal mb-1'> {user[scoreToConsider]}</p>
            </td>
            <td>
             <p className='fw-normal mb-1'> {"maxStreak" in user? user.maxStreak: 1}</p>
            </td>
          </tr>
        </MDBTableBody>
          )
        )
        }
      </MDBTable>
    </div>
  )
}
