import React from 'react'
import { useState, useCallback  } from 'react'
import { useLogin } from '../../hooks/useLogin'
import { useSignupGoogle } from '../../hooks/useSignupGoogle'
import { projectAuth } from '../../firebase/config'
//import styles from './Login.module.css'
import {
  MDBContainer,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox
}
from 'mdb-react-ui-kit';


export default function Login() {
  const [email, setEmail] = useState('')
  const [emailResetPassword, setEmailResetPassword] = useState('')
  const [password, setPassword] = useState('')
  const { login, error, isPending} = useLogin()
  const [disableSubmit,setDisableSubmit] = useState(true);
  const [disableSubmitGoogle, setDisableSubmiGoogle] = useState(true);
  const { signupGoogle, isPendingGoogle, errorGoogle} = useSignupGoogle()

  const handleSubmit = (e) =>{
    e.preventDefault()
    login(email, password)
  }

  const handleResetPassword = (e) =>{
    e.preventDefault()
    projectAuth.sendPasswordResetEmail(emailResetPassword)
    .then(() => {
      // Password reset email sent!
      // ..
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // ..
    });
  }

/*
  const handleSubmitGoogle = (e) =>{
    e.preventDefault()
    signupGoogle(null)
  }
*/

  return (
    <MDBContainer className="p-3 my-5 d-flex flex-column w-50">
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <MDBInput wrapperClass='mb-4' 
          label='Email address' 
          id='form1' 
          type='email' 
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <MDBInput 
          wrapperClass='mb-4' 
          label='Password' 
          id='form2' 
          type='password'
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          />

          <div className="d-flex justify-content-between mx-3 mb-4">
            <MDBCheckbox onChange={useCallback(() => setDisableSubmit(!disableSubmit))} name='flexCheck' value='' id='flexCheckDefault' label={
              <p>I agree to the <a href="https://www.iubenda.com/privacy-policy/87008971" target="_blank">privacy policy</a>, the <a href="https://www.iubenda.com/privacy-policy/87008971/cookie-policy" target="_blank">cookie policy</a>, the <a href="https://www.iubenda.com/terms-and-conditions/87008971" target="_blank">Terms and conditions</a> and confirm that the username does not contain
              any <a href="https://gdpr.eu/eu-gdpr-personal-data/" target="_blank">Personally Identifiable Information. </a></p>
              } />
          </div>

        {!isPending && <MDBBtn className="mb-4" disabled={disableSubmit}>Sign in</MDBBtn>}


        { isPending && <button className='btn' disabled>loading</button>}

        <div className="text-center"> 
          <p className="fs-7">Or Reset Password:</p>
        </div>
      </form>
      <form onSubmit={handleResetPassword}>
        <MDBInput wrapperClass='mb-4' 
          label='Email address' 
          id='formReset' 
          type='email' 
          onChange={(e) => setEmailResetPassword(e.target.value)}
          value={emailResetPassword}
        />

        {!isPending && <MDBBtn className="mb-4" >Send Email to reset password</MDBBtn>}


        { isPending && <button className='btn' disabled>loading</button>}
      </form>

    </MDBContainer>
  );


  /*
      <div className="text-center">
        <p className="fs-7">Or Login with Google:</p>
        <div className="d-flex justify-content-between mx-3 mb-4">
            <MDBCheckbox onChange={useCallback(() => setDisableSubmiGoogle(!disableSubmitGoogle))} name='flexCheck' value='' id='flexCheckDefault' label={
              <p>I agree to the <a href="https://www.iubenda.com/privacy-policy/87008971" target="_blank">privacy policy </a>, the <a href="https://www.iubenda.com/privacy-policy/87008971/cookie-policy" target="_blank">cookie policy </a> and confirm that the login details don't contain
              any sensitive data.</p>
              } />
          </div>
        <form onSubmit={handleSubmitGoogle}>
            <MDBBtn className="mb-4" disabled={disableSubmitGoogle}>Login with Google</MDBBtn>
        </form>
      </div>
  */
}
