const Footer = () => {
    const year = new Date().getFullYear();
  
    return <footer>
      <a href="https://www.iubenda.com/privacy-policy/87008971" target="_blank">Privacy Policy </a>
      <p></p>
       <a href="https://www.iubenda.com/privacy-policy/87008971/cookie-policy" target="_blank">Cookie Policy</a>
       <p></p>
       <a href="https://www.iubenda.com/terms-and-conditions/87008971" target="_blank">Terms and conditions</a></footer>;
  };
  
  export default Footer;