import { useState, useEffect } from "react"
import {projectAuth} from '../firebase/config'
import { useAuthContext } from "./useAuthContext"
import { useLogout} from '../hooks/useLogout'

export const useSignup = () =>{
    const [error, setError] = useState(null)
    const [isCancelled, setIsCancelled] = useState(false)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()
    const { logout } = useLogout()

    const signup = async (email, password, displayName) => {
        // We want to clean the error if there was one already
        setError(null)
        setIsPending(true)

        try {
            // firebase automatically logs user in when they sign up.
            const res = await projectAuth.createUserWithEmailAndPassword(email, password)
            if (!res) {
                throw new Error('could not complete signup')
            }

            // add displayName to user
            await res.user.updateProfile({
                displayName: displayName
            })

            // dispatch login action
            dispatch( { type: 'LOGIN', payload: res.user})

            projectAuth.currentUser.sendEmailVerification().then(()=>{
            });

            logout()
            
            // update state only if the action was not cancelled (by going to another page)
            if (!isCancelled){
                setIsPending(false)
                setError(null)
            }

        } catch (err){
            if (!isCancelled){
                setError(err.message)
                setIsPending(false)
            }
        }
    }

    // cleanup function, this gets fired if we navigate away from the component
    useEffect( ()=>{
        return () => setIsCancelled(true)
    }, [])

    return { error, isPending, signup}
}