import { blockedWords } from "./blockedWords";

export const containsSpecialChars = (str) => {
    const specialChars =
      /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }


export const containBlockedWords = (username) =>{
    for (var i=0; i< blockedWords.length; i++){
        if (username.includes(blockedWords[i])){
            return true
        }
        }
    return false
}


export const checkValidityInBoard = (data) =>{
    if ("username" in data 
        && "totalScore" in data
        && (typeof data["totalScore"] == "number")
        && !containsSpecialChars(data["username"])
        && !containBlockedWords(data["username"])){
            return true
        }
}