import React from 'react'
import './Result.css'
import { useState, useEffect } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
import { projectFirestore } from '../../firebase/config'
import { Link } from 'react-router-dom'

export default function ResultSection({totalScore, quizLength, userId}) {
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(false)
  const { user } = useAuthContext()
  const [initialScoreInfoInDB, setInitialScoreInfoInDB] = useState(null)


  return (
    <div className="result">
    <h3>Result</h3>
      {!user && <h4>You are currently not logged in. <Link to="/signup" className='nav-normal'>Signup</Link> and redo the quiz to save your results. </h4>}
      {user && <h4>Check your position in the <Link to="/leaderboard" className='nav-normal'>Leaderboard </Link></h4>}
   
      <p>
        Total Questions: <span>{quizLength}</span>
      </p>
      <p>
        Total Score:<span> {totalScore}</span>
      </p>
    { user && 
        <div>
        {isPending && <p className='loading'> Loading... </p>}
        {error && <p className='error'> {error} </p>}
        </div>
    }
</div>
  )
}
