import { createContext, useReducer } from 'react'

export const ModalContext = createContext()

const modalReducer = (state, action) => {
    // depending on the type we return different values
    console.log('state: ', state)
    console.log('action: ', action)
    switch (action.type) {
        case 'CHANGE_SHOW_MODAL':
            return { ...state, showModal: false }
        default:
            return state
    }
}


export function ModalProvider({ children }) {
    const [state, dispatch] = useReducer(modalReducer, {
        showModal: true
    })

    const changeModal = (showModal) => {
        console.log('show modal: ', showModal)
        dispatch({
            type: 'CHANGE_SHOW_MODAL', payload: showModal
        })
    }


    return (
        // value will be an object with 2 properties, the value and the function
        <ModalContext.Provider value={{ ...state, changeModal }}>
            {children}
        </ModalContext.Provider>
    )
}