

import { useState, useEffect } from "react"
import {projectAuth} from '../firebase/config'
import { useAuthContext } from "./useAuthContext"
import {firebase} from '../firebase/config'

export const useSignupGoogle = () =>{
    const [error, setError] = useState(null)
    const [isCancelled, setIsCancelled] = useState(false)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()
    var provider = new firebase.auth.GoogleAuthProvider();


    const signupGoogle = async (displayName) => {
        // We want to clean the error if there was one already
        setError(null)
        setIsPending(true)
        try {
            projectAuth
            .signInWithPopup(provider)
            .then((result) => {
              /** @type {firebase.auth.OAuthCredential} */
              var credential = result.credential;
          
              // This gives you a Google Access Token. You can use it to access the Google API.
              var token = credential.accessToken;
              // The signed-in user info.
              var user = result.user;
              
              if (displayName){
                result.user.updateProfile({
                    displayName: displayName
                })
              }


            dispatch( { type: 'LOGIN', payload: result.user})

        }).catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;

        });
        if (!isCancelled){
            setIsPending(false)
            setError(null)
        }
            // ...
        } catch (err){
            if (!isCancelled){
                setError(err.message)
                setIsPending(false)
            }
        }
    }

    // cleanup function, this gets fired if we navigate away from the component
    useEffect( ()=>{
        return () => setIsCancelled(true)
    }, [])

    return { error, isPending, signupGoogle}
}