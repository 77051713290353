import { Link } from 'react-router-dom'
import { useLogout} from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
import React, { useState } from 'react';
// styles

import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBCollapse,
  } from 'mdb-react-ui-kit';


export default function Navbar() {
    const { color} = '#249c6b'
    const { user } = useAuthContext()
    const { logout } = useLogout()
    const [showBasic, setShowBasic] = useState(false);


    return (
        <MDBNavbar expand='lg' dark bgColor='primary'>
          <MDBContainer fluid>

            <MDBNavbarBrand tag={Link} to='/'>AI NewsQuiz</MDBNavbarBrand>

            <MDBNavbarToggler
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
              onClick={() => setShowBasic(!showBasic)}
            >
              <MDBIcon icon='bars' fas />
            </MDBNavbarToggler>
    
            <MDBCollapse navbar show={showBasic}>
              <MDBNavbarNav className='me-auto mb-2 mb-lg-0'>
              


                    <MDBNavbarLink tag={Link} to='/leaderboard'>
                        Leaderboard
                    </MDBNavbarLink>
                { !user &&
                      <>
                        <MDBNavbarLink tag={Link} to='/login'>
                            Login
                        </MDBNavbarLink>

                    <MDBNavbarLink tag={Link} to='/signup'>
                        Signup
                    </MDBNavbarLink>
                    </>
                }
                {user && 
                  <>
                    <MDBNavbarLink onClick={logout}>
                        Logout
                    </MDBNavbarLink>
                    <MDBNavbarLink tag={Link} to='/account-info'>
                      AccountInfo
                    </MDBNavbarLink>
                </>
                }
    
                <MDBNavbarItem>
                          <MDBNavbarLink tag={Link} to='/about' className='nav-normal'>
                              About
                          </MDBNavbarLink>
                </MDBNavbarItem>
            
              </MDBNavbarNav>
  
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      );
}
