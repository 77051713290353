import { useEffect, useState } from "react"
import { projectAuth } from "../firebase/config"
import { useAuthContext } from "./useAuthContext"

export const useLogin = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [ error, setError] = useState(null)
    const [ isPending, setIsPending ] = useState(false)
    const { dispatch } = useAuthContext()

    const login = async (email, password) => {
        setError(null)
        setIsPending(true)

        // sign the user out
        try{
            // we wait until the user is logged out and then we dispatch the action
            const res = await projectAuth.signInWithEmailAndPassword(email, password)

            // dispatch logout action
            dispatch({ type: 'LOGIN', payload: res.user}) 

            // update state only if the action was not cancelled (by going to another page)
            if (!isCancelled){
                setIsPending(false)
                setError(null)
            }

        } catch (err){
            if (!isCancelled){
                setError(err.message)
                setIsPending(false)
            }
        }
    }

    // cleanup function, this gets fired if we navigate away from the component
    useEffect( ()=>{
        return () => setIsCancelled(true)
    }, [])
    return { login, error, isPending}
}