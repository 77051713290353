const computeYesterday = () =>{
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday = new Date( yesterday.toISOString().split('T')[0])
    return yesterday
}


export const updateStrike = (personScore) =>{
    let lastExecutionDate = new Date(personScore.lastExecution);
    let yesterday = computeYesterday()
    personScore.totalGamePlayed +=1

    if (lastExecutionDate < yesterday){
        console.log("you didn't play yesterday")
        personScore.currentStreak = 1
        return personScore
    }
    console.log("Nice job!")
    personScore.currentStreak +=1
    if (personScore.currentStreak > personScore.maxStreak){
        personScore.maxStreak +=1
    }
    return personScore
}