import { projectFirestore } from '../../firebase/config'
import firebase from 'firebase/app'
import { updateStrike } from '../../utils/strikeLogic';
import { computeScoreWeek } from '../../utils/weekScoreLogic';

export const updateLeaderBoard = (scoreUpdated) =>{
    const leaderBoardDB = projectFirestore.collection('leaderBoard').doc('boardScores');
    leaderBoardDB.get().then( (doc) =>{
        if (doc.exists){
            var leaderboardUpdated = doc.data()
            delete scoreUpdated.playedGames
            leaderboardUpdated[scoreUpdated["userId"]] = scoreUpdated
            leaderBoardDB.update(
                leaderboardUpdated
            )
        }
    })
}


export const updateResult = (userId, totalScore, quizId, username) => {
    const today = new Date();
    // The month is without 0 (so 25th of july is 2022-7-25)
    const formattedDate = today.getFullYear() + '-' 
           + ('0' + (today.getMonth() + 1)).slice(-2) + '-' 
           + ('0' + today.getDate()).slice(-2);

    
    const userScoreDB = projectFirestore.collection('leaderBoardScores').doc(userId);
    userScoreDB.get().then((doc) =>{
        if (doc.exists){
            var scoreUpdated = doc.data()
            // if user has not played today
                if ( !(formattedDate === scoreUpdated["lastExecution"])){
                    scoreUpdated["lastExecution"] = formattedDate
                    scoreUpdated["totalScore"] += totalScore
                    scoreUpdated["playedGames"][formattedDate] = totalScore
                    scoreUpdated["scoreWeek"] = computeScoreWeek(scoreUpdated)
                    scoreUpdated = updateStrike(scoreUpdated)
                    userScoreDB.update(
                        scoreUpdated
                    )
                }
            } else{
                // first time the user ever plays
                scoreUpdated = {
                    "lastExecution": formattedDate,
                    "totalScore": totalScore,
                    "currentStreak": 1,
                    "maxStreak": 1,
                    "totalGamePlayed": 1,
                    "userId": userId,
                    "username": username,
                    "scoreWeek": totalScore,
                    "currentStreak": 1,
                    "maxStreak":1
                }
                
                scoreUpdated[formattedDate] = totalScore

                userScoreDB.set(
                    scoreUpdated
                )
            }

        // call update leaderboard
        updateLeaderBoard(scoreUpdated)
    }).catch(
        (err) =>{
            console.error("Error updating/creating data for Leaderboard", err)
        }

    )
  }