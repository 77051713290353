import React, { useState } from 'react';
import { useModal } from '../hooks/useModal';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom'

export default function Modal() {
  const { changeModal, showModal } = useModal()
  const [basicModal, setBasicModal] = useState(true);

  const toggleShow = () => setBasicModal(!basicModal);

  const acceptConditions = () => {
    changeModal(false)
  }

  console.log(showModal)
  return (
    <div
      id="staticBackdrop"
      data-mdb-backdrop="static"
      data-mdb-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      {
        showModal &&
        <MDBModal
          data-mdb-backdrop="static"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          show={showModal} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent data-mdb-backdrop="static">
              <MDBModalHeader>
                <MDBModalTitle>Terms and Conditions</MDBModalTitle>
              </MDBModalHeader>
              <MDBModalBody>
                By continuing, you agree to the <a href="https://www.iubenda.com/terms-and-conditions/87008971" target="_blank">Terms and conditions</a>,
                our <a href="https://www.iubenda.com/privacy-policy/87008971" target="_blank">Privacy Policy </a> and our <a href="https://www.iubenda.com/privacy-policy/87008971/cookie-policy" target="_blank">Cookie Policy.</a>
                <p></p>
                You also understand that the Quiz is automatically generated by algorithms and might not always be accurate. Check the <Link to="/about" className='nav-normal'>about </Link> section to know more
              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn onClick={acceptConditions}>
                  Ok
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      }
    </div>
  )
}