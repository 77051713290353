import { projectFirestore } from '../../firebase/config'

export const createSingleQuestionStatObject = (isCorrect, stats, questionId) =>{
    if (stats
        && stats != undefined
        && stats[questionId] != undefined){
        if (isCorrect){
            var newTotalAnswer = stats[questionId]['countTotalAnswers']+ 1
            var newCorrectAnswers = stats[questionId]['countCorrectAnswers']+ 1
            var newPercentage = Math.trunc(100* newCorrectAnswers/newTotalAnswer)
            return { id: questionId, 
                'countTotalAnswers': newTotalAnswer,
                'countCorrectAnswers': newCorrectAnswers,
                'percentageCorrect': newPercentage
               }
        } else {
            var newPercentage = Math.trunc(100* stats[questionId]['countCorrectAnswers']/ (stats[questionId]['countTotalAnswers']+ 1) )
            return { id: questionId, 
                'countTotalAnswers': stats[questionId]['countTotalAnswers']+ 1,
                'countCorrectAnswers': stats[questionId]['countCorrectAnswers'],
                'percentageCorrect': newPercentage
               }
        }
    }
}


export const updateStats = (questionStatsArray, quizId) => {
    
    if (questionStatsArray
        && questionStatsArray != undefined
        && questionStatsArray.length > 0){
        const scoreStatsRef = projectFirestore.collection('questionStatistics').doc(quizId);
        scoreStatsRef.get().then((doc) =>{
            if (doc.exists){
                var originalDocument = doc.data()
                questionStatsArray.map( (el, index) => {
                    if ( el !=undefined
                         && ("id" in el) 
                         && (el["id"] in originalDocument) ){
                        originalDocument[el["id"]] = el
                    } 
                })
                scoreStatsRef.set(
                    originalDocument
                )
            }
    
        }).catch(
            (err) =>{
                console.error("Error updating/creating the stats", err)
            }  
        )
    }

}