import React from 'react'
import { useState, useCallback } from 'react'
import { useSignup } from '../../hooks/useSignup'
import { containsSpecialChars, containBlockedWords} from '../../utils/inputValidation'

import {
  MDBContainer,
  MDBBtn,
  MDBInput,
  MDBCheckbox
}
from 'mdb-react-ui-kit';

export default function Signup() {
  const [disableSubmit,setDisableSubmit] = useState(true);
  const [email, setEmail] = useState('')
  const [usernameTooLong, setUsernameTooLong] = useState(false)
  const [errorInUsername, setErrorInUsername] = useState(false)
  const [password, setPassword] = useState('')
  const [displayName, setDisplayName] = useState('')
  const { signup, isPending, error} = useSignup()


  console.log(errorInUsername)

  const handleSubmit = (e) =>{
    e.preventDefault()

    if (displayName.length > 20){
      setUsernameTooLong(true)
      return
    }
    if ( (! (displayName === "") )
      &&  (!containsSpecialChars(displayName))
      && (!containBlockedWords(displayName))
    ){
      signup(email, password, displayName)
    }else{
      setErrorInUsername(true)
    }

  }

  
  return (
    <MDBContainer className="p-3 my-5 d-flex flex-column w-50">
      <h1>Signup</h1>
      {errorInUsername &&
      <p>Your username has invalid characters or words</p>
      }
      {
        usernameTooLong &&
        <p>Username cannot be longer than 20 characters</p>
      }
      <form onSubmit={handleSubmit}>
        <MDBInput wrapperClass='mb-4' 
            label='Username' 
            id='form1' 
            type='text' 
            onChange={(e) => setDisplayName(e.target.value)}
            value={displayName}
          />
          <MDBInput wrapperClass='mb-4' 
            label='Email address' 
            id='form2' 
            type='email' 
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <MDBInput 
            wrapperClass='mb-4' 
            label='Password' 
            id='form3' 
            type='password'
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            />
          <div className="d-flex justify-content-between mx-3 mb-4">
            <MDBCheckbox onChange={useCallback(() => setDisableSubmit(!disableSubmit))} name='flexCheck' value='' id='flexCheckDefault' label={
              <p>I agree to the <a href="https://www.iubenda.com/privacy-policy/87008971" target="_blank">privacy policy</a>, the <a href="https://www.iubenda.com/privacy-policy/87008971/cookie-policy" target="_blank">cookie policy</a>, the <a href="https://www.iubenda.com/terms-and-conditions/87008971" target="_blank">Terms and conditions</a> and confirm that the username does not contain
              any <a href="https://gdpr.eu/eu-gdpr-personal-data/" target="_blank">Personally Identifiable Information. </a></p>
              } />
          </div>
          <p className="fs-7">We'll send a confirmation email, click it to verify your account. (check also the spam folder)</p>
          {!isPending && <MDBBtn className="mb-4" disabled={disableSubmit}>Sign Up</MDBBtn>}
          { isPending && <button className='btn' disabled>loading</button>}
      </form>



    </MDBContainer>
  );

  /*
      <div className="text-center">
      <div className="d-flex justify-content-between mx-3 mb-4">
            <MDBCheckbox onChange={useCallback(() => setDisableSubmiGoogle(!disableSubmitGoogle))} name='flexCheck' value='' id='flexCheckDefault' label={
              <p>I agree to the <a href="https://www.iubenda.com/privacy-policy/87008971" target="_blank">privacy policy </a>, the <a href="https://www.iubenda.com/privacy-policy/87008971/cookie-policy" target="_blank">cookie policy </a> and confirm that the login details don't contain
              any sensitive data.</p>
              } />
          </div>
        <>
          <form onSubmit={handleSubmitGoogle}>
          <MDBInput wrapperClass='mb-4' 
            label='Username' 
            id='form4' 
            type='text' 
            onChange={(e) => setUsernameGoogle(e.target.value)}
            value={usernameGoogle}
          />

          {!isPendingGoogle && 
            <MDBBtn tag='a' color='none' className='mb-4'>
              
              <MDBBtn className="mb-4" disabled={!usernameGoogle || disableSubmitGoogle}>Sign Up with Google</MDBBtn>
            </MDBBtn>
            } 
          </form>
        </>
    
      </div>

  */
}
