

export const computeScoreWeek = (personScore) =>{
    try {
        var currentDate = new Date();
        var dayWeek = (currentDate.getDay() + 6) % 7
        var scoreWeek = 0;
        console.log(dayWeek) 
        for (let i = 0; i < dayWeek +1; i++) {
            const formattedDate = currentDate.getFullYear() + '-' 
            + ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' 
            + ('0' + currentDate.getDate()).slice(-2);
            console.log(formattedDate)
    
            if (formattedDate in personScore.playedGames){
                scoreWeek += personScore['playedGames'][formattedDate]
            }
            currentDate.setDate(currentDate.getDate() - 1);
          }
        console.log('Total score week: ', scoreWeek)
    
        return scoreWeek
    } catch {
        return 0
    }
}
