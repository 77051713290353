import React from 'react'
import { projectAuth } from '../../firebase/config'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useLogout} from '../../hooks/useLogout'
import { projectFirestore } from '../../firebase/config'
import { updateLeaderBoard } from '../../components/ResultSection/UpdateResult'
import {
    MDBBtn
  }
  from 'mdb-react-ui-kit';

export default function AccountInfo() {
  const { authIsReady, user } = useAuthContext()
  const { logout } = useLogout()

  const handleDeleteUser = () =>{
    projectFirestore.collection("leaderBoardScores").doc(user.uid).delete().then(() => {
        updateLeaderBoard({
          "lastExecution": '',
          "totalScore": 0,
          "userId": user.uid,
          "username": ''
        })
        console.log('deleted score in Leaderboard')
        user.delete().then(() => {
          // User deleted.
          logout()
        }).catch((error) => {
          console.log(error)
          logout()
        });
    }).catch((error) => {
        console.error("Error removing document: ", error);
        user.delete().then(() => {
          // User deleted.
          logout()
        }).catch((error) => {
          console.log(error)
          logout()
        });
    });
  }

const handleEmailVerification = () =>{
  projectAuth.currentUser.sendEmailVerification().then(()=>{
    console.log('Sent email to verify the user')
    logout()
  });
}
  return (
    <div>
        <h1>AccountInfo</h1>
        <h2>Hello {user.displayName}</h2>
        {
          user.emailVerified && 
          <p>Your email was verified!</p>
        }
        {
          !user.emailVerified && 
          <>
          <p>Please verify your email</p>
          <MDBBtn className="mb-4"  onClick={handleEmailVerification}>Send verification email</MDBBtn>
          </>
        }
        <p></p>
        <MDBBtn className="mb-4"  onClick={logout}>Logout</MDBBtn>
        <p></p>
        <MDBBtn className="mb-4" onClick={handleDeleteUser}>Delete Account</MDBBtn>
        </div>
  )
}
