import './App.css';
//import Quiz from './components/Quiz';
import QuizView from './components/QuizView';
import {BrowserRouter, Route, Switch, NavLink, Redirect} from 'react-router-dom'
import Navbar from './components/Navbar'
import About from './pages/about/About';
import Login from './pages/login/Login'
import Signup from './pages/signup/Signup'
import Board from './pages/board/board';
import Footer from './components/Footer';
import AccountInfo from './pages/personal/AccountInfo';
import { useAuthContext } from './hooks/useAuthContext'

function App() {
  const { authIsReady, user } = useAuthContext()
  
  return (
    <div className="App">
      { authIsReady && (
      <BrowserRouter>
        <Navbar/>
        <Switch>
          <Route exact path="/">
            <QuizView/>
          </Route>
          <Route exact path="/about">
            <About/>
          </Route>
          <Route exact path="/leaderboard">
            <Board/>
          </Route>
        <Route exact path="/login">
            { user && <Redirect to="/" />}
            {!user && <Login/>}
          </Route>
          <Route exact path="/account-info">
            { !user && <Redirect to="/" />}
            {user && <AccountInfo/>}
          </Route>
          <Route exact path="/signup">
            { user && <Redirect to="/" />}
            {!user && <Signup/>}
          </Route>
          </Switch>
          <Footer/>
      </BrowserRouter>
      )}
    </div>
  );
}

export default App;
