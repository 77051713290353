
import { useState, useEffect } from 'react'
import { projectFirestore } from '../../firebase/config'
import {  MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from 'mdb-react-ui-kit';
import { checkValidityInBoard } from '../../utils/inputValidation';
import BoardList from './BoardList';

export default function Board() {
  const [period, setPeriod] = useState(0);
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(false)
  const [leaderBoardList, setLeaderBoardList] = useState(null)
  const [weeklyLeaderBoardList, setWeeklyLeaderBoardList] = useState(null)
  // style at https://mdbootstrap.com/docs/react/components/buttons/#docsTabsOverview
  const [leaderboardType, setLeaderboardType] = useState('weekly')

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    setIsPending(true)
    const unsub = projectFirestore.collection('leaderBoard').doc('boardScores').onSnapshot((doc)=>{
      if (doc.exists) {
          setIsPending(false)
          const data = doc.data()

          var arrayScoreList = Object.keys(data)
          .map(function(key) {
              return data[key];
          });
          arrayScoreList = arrayScoreList.filter(function(item){
              return checkValidityInBoard(item)
            }
          )
          const sortedList = sortAndFilter(arrayScoreList, period, "totalScore")
          setLeaderBoardList(sortedList)
          setWeeklyLeaderBoardList(
            sortAndFilter(arrayScoreList, period, "scoreWeek")
          )
        }
        else {
          setIsPending(false)
          setError('could not find the leaderboard information')
      }
    })
    // this fires is the component unmounts.
    return () => unsub()
  }, [])

  return (
    <div>
        {isPending && <p className='loading'> Loading... </p>}
        {error && <p className='error'> {error} </p>}
        <h1 className='align-items-center'>{capitalizeFirstLetter(leaderboardType)} Leaderboard</h1>
        <p></p>
        <>
      <MDBBtn rounded color={leaderboardType=='weekly'? 'dark': 'light'}
      onClick={() =>{setLeaderboardType('weekly')}}>
        Weekly Leaderboard
      </MDBBtn>
      <MDBBtn rounded color={leaderboardType=='global'? 'dark': 'light'}
        onClick={() =>{setLeaderboardType('global')}}>
        Global Leaderboard
      </MDBBtn>
    </>
    <p></p>
        {leaderBoardList && leaderboardType==="global" &&
        //(leaderboardType === 'weekly'? weeklyLeaderBoardList: leaderBoardList)
          <BoardList 
          listOfScores = {leaderBoardList}
          scoreToConsider = {"totalScore"}
          />
        } 
        {weeklyLeaderBoardList && leaderboardType==="weekly" &&
        //(leaderboardType === 'weekly'? weeklyLeaderBoardList: leaderBoardList)
          <BoardList 
          listOfScores = {weeklyLeaderBoardList}
          scoreToConsider = {"scoreWeek"}
          />
        } 
    </div>
  );

}

//{leaderBoardList && <Profiles Leaderboard={between(leaderBoardList, period)}></Profiles>}
function sortAndFilter(data, between, scoreToConsider){
  const today = new Date();
  const previous = new Date(today);
  previous.setDate(previous.getDate() - (between + 100));

  let filter = data.filter(val => {
      let userDate = new Date(val.lastExecution);
      // filter user that have been cancelled and don't have score 
      if (between == 0) return val && val[scoreToConsider] >0;
      return previous <= userDate && today >= userDate && val[scoreToConsider] >0;
  })

  // sort with asending order
  return filter.sort((a, b) => {
      if ( a[scoreToConsider] === b[scoreToConsider] ){
          return b[scoreToConsider]  - a[scoreToConsider] ;
      } else{
          return b[scoreToConsider]  - a[scoreToConsider] ;
      }
  })

}



/*
        leaderBoardList.map( (user, index) => (
          <MDBTableBody key={index}>
          <tr >
          <td>
             <p className='fw-normal mb-1'> {index +1}</p>
            </td>
            <td>
              <div className='align-items-center'>
                <div className='ms-3'>
                  <p className='fw-bold mb-1'>{user.username}</p>
                </div>
              </div>
            </td>

            <td>
             <p className='fw-normal mb-1'> {user.totalScore}</p>
            </td>
          </tr>
        </MDBTableBody>
          )
        )
*/