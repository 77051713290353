import React from 'react'
import { useState, useEffect, useRef } from 'react'
import SingleQuiz from './SingleQuiz'
import { projectFirestore } from '../firebase/config'
import { useAuthContext } from '../hooks/useAuthContext'
import ResultSection from './ResultSection/ResultSection'
import { updateResult } from './ResultSection/UpdateResult'
import { updateStats, createSingleQuestionStatObject } from './ResultSection/updateStats'
import { projectAuth } from '../firebase/config'
import { Link } from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import Modal from './Modal'
import image from '../assets/041.png'

import {
  MDBBtn
}
  from 'mdb-react-ui-kit';



export default function QuizView() {
  // score related data
  const [showResult, setShowResult] = useState(false)
  const [totalScore, setTotalScore] = useState(0)

  const [quizToDisplay, setQuizToDisplay] = useState('international')

  const { logout } = useLogout()

  const { authIsReady, user } = useAuthContext()

  const [questionStats, setQuestionStats] = useState(null)
  // this is the one to send to firebase
  const [questionStatsArray, setQuestionStatsArray] = useState([])


  const [quiz, setQuiz] = useState(null)
  const [quizIlPost, setQuizIlPost] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(false)

  const today = new Date();
  // The month is without 0 (so 25th of july is 2022-7-25)


  const quizId = today.getFullYear() + '-'
    + ('0' + (today.getMonth() + 1)).slice(-2) + '-'
    + ('0' + today.getDate()).slice(-2);



  const handleEmailVerification = () => {
    projectAuth.currentUser.sendEmailVerification().then(() => {
      console.log('Sent email to verify the user')
      logout()
    });
  }
  useEffect(() => {
    setIsPending(true)
    const unsub = projectFirestore.collection('newsQuiz').doc(quizId).onSnapshot((doc) => {
      if (doc.exists) {
        setIsPending(false)
        const initialData = doc.data()
        var array = Object.keys(initialData[quizId]["questions"])
          .map(function (key) {
            initialData[quizId]["questions"][key]["realKey"] = key
            return initialData[quizId]["questions"][key];
          });

        try {
          var arrayilPost = Object.keys(initialData["ilPostQuiz"]["questions"])
            .map(function (key) {
              initialData["ilPostQuiz"]["questions"][key]["realKey"] = key
              return initialData["ilPostQuiz"]["questions"][key];
            });
        } catch {
          var arrayilPost = []
        }

        const newData = {
          "quizTitle": initialData[quizId]["quizTitle"],
          "questions": array.slice(0, 5)
        }

        const ilPostQuiz = {
          "quizTitle": initialData["ilPostQuiz"]["quizTitle"],
          "questions": arrayilPost.slice(0, 5)
        }

        setQuiz(newData)
        setQuizIlPost(ilPostQuiz)
      } else {
        setIsPending(false)
        setError('could not find the quiz for today')
      }
    })
    // this fires is the component unmounts.
    return () => unsub()
  }, [quizId])

  // fetch question stats
  useEffect(() => {
    setIsPending(true)
    const unsub = projectFirestore.collection('questionStatistics').doc(quizId).onSnapshot((doc) => {
      if (doc.exists) {
        setIsPending(false)
        setQuestionStats(doc.data())
      } else {
        setIsPending(false)
        setError('could not find the quiz for today')
      }
    })
    // this fires is the component unmounts.
    return () => unsub()
  }, [quizId])

  const handleFinishQuiz = (lastAnswerCorrect, questionId, questionsStats) => {
    setShowResult(true)

    questionStatsArray.push(
      createSingleQuestionStatObject(lastAnswerCorrect, questionsStats, questionId)
    )
    updateStats(questionStatsArray, quizId)

    if (user && user.uid && user.uid !== undefined && user.displayName != undefined) {
      if (lastAnswerCorrect) {
        updateResult(user.uid, totalScore + 1, quizId, user.displayName)
      } else {
        updateResult(user.uid, totalScore, quizId, user.displayName)
      }
    }
  }


  // this is called only if the answer selected was the correct one

  const handleSelectAnswer = (questionId, isCorrect, stats) => {
    if (stats !== null && stats != undefined) {
      if (isCorrect) {
        setTotalScore(totalScore + 1)

        const newQuestionStat = createSingleQuestionStatObject(isCorrect, stats, questionId)

        setQuestionStatsArray( // Replace the state
          [ // with a new array
            ...questionStatsArray, // that contains all the old items
            newQuestionStat // and one new item at the end
          ]
        );
      } else {
        const newQuestionStat = createSingleQuestionStatObject(isCorrect, stats, questionId)
        //var newPercentage = Math.trunc(100* stats[questionId]['countCorrectAnswers']/ (stats[questionId]['countTotalAnswers']+ 1) )
        setQuestionStatsArray( // Replace the state

          [ // with a new array
            ...questionStatsArray, // that contains all the old items
            newQuestionStat
          ]
        );
      }

    }
  }

  // style={{ backgroundImage: "url('https://mdbootstrap.com/img/new/slides/041.webp')", height: '600px' }}

  return (
    <div>
      <div
        className='p-15 text-center bg-image'
        style={{ backgroundImage: "url('https://mdbootstrap.com/img/new/slides/041.webp')", height: '600px' }}
      >
        <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-white'>
              <h1 className='mb-3'>AI News Quiz</h1>
              <h4 className='mb-3'>Test your knowledge with the first AI-generated News Quiz</h4>
              <small>
                The quiz is based on news articles from <a href="https://www.theguardian.com/international" target="_blank">The Guardian</a> and <a href="https://www.ilpost.it" target="_blank">IlPost</a>
              </small>
              <p></p>
              {!user &&
                <>
                  <small>
                    To save the results and see your score in the <Link to="/leaderboard" className='nav-normal'>Leaderboard </Link>  signup <Link to="/signup" className='nav-normal'>here </Link>
                  </small>
                  <p></p>
                </>
              }

            </div>
          </div>
        </div>
      </div>
      <p></p>
      <p></p>
      <p></p>
      <>
        <MDBBtn rounded color={quizToDisplay == 'international' ? 'dark' : 'light'}
          onClick={() => { setQuizToDisplay('international') }}>
          International Quiz
        </MDBBtn>
        <MDBBtn rounded color={quizToDisplay == 'quizIlPost' ? 'dark' : 'light'}
          onClick={() => { setQuizToDisplay('quizIlPost') }}>
          Quiz Italiano
        </MDBBtn>
      </>
      <Modal />
      <p></p>
      {user && !user.emailVerified &&
        <>
          <p>To save the quiz result you need to verify your email:</p>
          <MDBBtn className="mb-4" onClick={handleEmailVerification}>Send verification email</MDBBtn>
        </>
      }
      {isPending && <p className='loading'> Loading... </p>}
      {error && <p className='error'> {error} </p>}
      {quizToDisplay === 'quizIlPost' && quizIlPost && questionStats &&
        quizIlPost.questions.map((question, index) => (
          <SingleQuiz
            key={question.question}
            quizObject={{
              question: question.question,
              choices: question.choices,
              url: question.url,
              totalNumberQuestions: quizIlPost.questions.length,
              quizId: quizId
            }}
            actualQuiz={question}
            questionsStats={questionStats}
            questionId={question.questionId}
            indexCorrectAnswer={question.indexCorrectAnswer}
            indexCurrentQuestion={index}
            handleFinishQuiz={handleFinishQuiz}
            handleSelectAnswer={handleSelectAnswer}
          />
        )
        )
      }
      {quizToDisplay === 'international' && quiz && questionStats &&
        quiz.questions.map((question, index) => (
          <SingleQuiz
            key={question.question}
            quizObject={{
              question: question.question,
              choices: question.choices,
              url: question.url,
              totalNumberQuestions: quiz.questions.length,
              quizId: quizId
            }}
            actualQuiz={question}
            questionsStats={questionStats}
            questionId={question.questionId}
            indexCorrectAnswer={question.indexCorrectAnswer}
            indexCurrentQuestion={index}
            handleFinishQuiz={handleFinishQuiz}
            handleSelectAnswer={handleSelectAnswer}
          />
        )
        )
      }

      {
        showResult &&
        <ResultSection
          totalScore={totalScore}
          quizLength={quiz.questions.length}
          userId={user ? user.uid : ''}
        />
      }
    </div>
  )
}