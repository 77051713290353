
// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
import 'firebase/firestore';
import 'firebase/auth';
import "firebase/functions";
import 'firebase/app-check';
import firebase from 'firebase/app'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDxay42ObgKxbpgp1uj6JUywT1rs7WoOKo",
  authDomain: "ainewsquiz-14cbe.firebaseapp.com",
  projectId: "ainewsquiz-14cbe",
  storageBucket: "ainewsquiz-14cbe.appspot.com",
  messagingSenderId: "651887574430",
  appId: "1:651887574430:web:74b56f434321deb49a2a8f"
};


//initialise firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig);

const appCheck = firebaseApp.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate(
  '6LdDTP8kAAAAAF0SoqZNLLrTGCp2jQBEEGqZ8rZ7',
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  true);


//initialise services
const projectFirestore = firebaseApp.firestore()
const projectAuth = firebaseApp.auth()

export { projectFirestore, projectAuth, firebase}