import React from 'react'
import './About.css'

export default function About() {
  return (
    <div className='about'>
        <h1>About</h1>
        Hey there, I am Marco, an italian developer living in Switzerland. In this page you can find some informations about how the project was developed and what's the idea behind it.
        <p></p>
        <h3>The Idea</h3>
        The main goal of ainewsquiz is to provide a fun way to stay up to date with World News. The initial inspiration for the project came after reading <a href="https://arxiv.org/pdf/2102.09094.pdf" target="_blank">this</a> paper from Google
        and doing some weekly quizzes from <a href="https://www.nytimes.com/spotlight/news-quiz" target="_blank">The New York Times</a>. Following the paper I initially trained a <a href="https://huggingface.co/t5-base" target="_blank">T5</a> model to generate Question-Answer pairs,
        using the <a href="https://github.com/google-research-datasets/NewsQuizQA" target="_blank">NewsQuizQA</a> dataset. The distractors were generated by using another T5 model, trained for  <a href="https://huggingface.co/google/t5-11b-ssm-tqa" target="_blank">closed book Question Answering</a>  and taking the first results of the Beam Search. 
        Unfortunately I quickly realised that the computational power required to have good results with these models (and train/serve them) was too expensive and I therefore decided to try using GPT-3. I also decided to directly generate the whole quiz, instead of splitting the task in two parts.
        I therefore manually created examples of quizzes based on news and I fine-tuned GPT-3 with them. Although not perfect, the results are encouraging and I constantly update the model to make it more realistic.
        <p></p>
        <h3>Limitations and Bias</h3>
        <p> 
            GPT-3 is a 175 billions parameter model, developed by <a href="https://openai.com/api/" target="_blank">Open-AI </a> and trained using text from the whole internet. 
            Most of these training data could not be manually reviewed by a person and GPT-3 has <a href="https://huggingface.co/course/chapter1/8?fw=pt" target="_blank">bias and limitations</a> as other language models.
            Large Language models are also known to occasionaly suffer from <a href="https://en.wikipedia.org/wiki/Hallucination_(artificial_intelligence)" target="_blank">Hallucination</a>, inventing facts and numbers. 
            To mitigate these problems I try to manually review the generated quizzes although this is not always possible and 100% correctness cannot be guaranteed.
        </p>
        <p></p>
        <h3>Credits</h3>
        This project uses News Articles from <a href="https://www.theguardian.com/international" target="_blank">The Guardian</a>, obtained through <a href="https://open-platform.theguardian.com/" target="_blank">their open platfrom  </a>. 
    </div>
  )
}
