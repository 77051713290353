import { useEffect, useState } from "react"
import { projectAuth } from "../firebase/config"
import { useAuthContext } from "./useAuthContext"


export const useLogout = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [ error, setError] = useState(null)
    const [ isPending, setIsPending ] = useState(false)
    const { dispatch } = useAuthContext()

    const logout = async () => {
        setError(null)
        setIsPending(true)

        // sign the user out
        try{
            // we wait until the user is logged out and then we dispatch the action
            await projectAuth.signOut()

            // dispatch logout action
            dispatch({ type: 'LOGOUT'}) 

            // update state only if the action was not cancelled (by going to another page)
            if (!isCancelled){
                setIsPending(false)
                setError(null)
            }

        } catch (err){
            if (!isCancelled){
                setError(err.message)
                setIsPending(false)
            }
        }
    }

    // cleanup function, this gets fired if we navigate away from the component
    useEffect( ()=>{
        return () => setIsCancelled(true)
    }, [])
    return { logout, error, isPending}
}