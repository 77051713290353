import { Children, createContext, useEffect, useReducer } from "react";
import { projectAuth } from "../firebase/config";

export const AuthContext = createContext()

export const authReducer = (state, action) =>{
    switch (action.type){
        case 'LOGIN':
            // when the user logs in we create a new object to represent the state
            // we keep what was there already and update the user property (with what is returned from firebase)
            return { ...state, user: action.payload}
        case 'LOGOUT':
            return { ...state, user: null}
        case 'AUTH_IS_READY':
            return { ...state, user: action.payload, authIsReady: true}
        default:
            return state
    }
}

// every time the state changes, this will get re-evaluated
export const AuthContextProvider = ({ children }) => {
    // before showing any component we query firebase to see if user is authenticated.
    // authIsReady will turn true when we receive an answer from Firebase (independently from the answer)
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        authIsReady: false
    })

    // fires once, when the component is evaluated
    useEffect(() => {
        const unsub = projectAuth.onAuthStateChanged((user) =>{
            dispatch({ type: 'AUTH_IS_READY', payload: user})
            // this way it will never fire again
            unsub()
        })
    }, [])

    return (
        <AuthContext.Provider value={ {...state, dispatch} }>
            { children}
        </AuthContext.Provider>
    )
}