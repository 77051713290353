import React from 'react'
import './Quiz.css'
import { useState, useEffect } from 'react'
//import LikeButton from './LikeButton/LikeButton'
import { useAuthContext } from '../hooks/useAuthContext'
import { MDBBtn } from 'mdb-react-ui-kit'
//import { projectFirestore } from '../firebase/config'
//import { act } from 'react-dom/test-utils'


export default function SingleQuiz({ 
                                     quizObject,   
                                     actualQuiz,
                                     questionsStats,
                                     indexCorrectAnswer, 
                                     indexCurrentQuestion, 
                                     handleFinishQuiz,
                                     handleSelectAnswer,
                                     quizId,
                                     questionId
                                    }) {
  
  const [ indexAnswerSelected, SetIndexAnswerSelected] = useState(null)
  const [ alreadySelectedAnAnswer, setAlreadySelectedAnAnswer] = useState(false)
  const { authIsReady, user } = useAuthContext()

  const today = new Date();

  const handleOnClickAnswer = (selectedIndex, questionsStats, questionId) =>{
        SetIndexAnswerSelected(selectedIndex)
        setAlreadySelectedAnAnswer(true)
        if (selectedIndex === indexCorrectAnswer){
            handleSelectAnswer(questionId, true, questionsStats)
        } else{
            handleSelectAnswer(questionId, false, questionsStats)
        }

        

        if (indexCurrentQuestion === quizObject.totalNumberQuestions -1){
            handleFinishQuiz(selectedIndex === indexCorrectAnswer, questionId, questionsStats)
        }
  }

  /*
        
            {
                'summary' in actualQuiz &&
                <p> {actualQuiz.summary} </p>
            }
  */

  const conditionallyRenderStats = (alreadySelectedAnAnswer) =>{
    if ( (alreadySelectedAnAnswer) 
        && (questionsStats != undefined)
        && (questionsStats[questionId] != undefined)
        && (typeof questionsStats[questionId]['percentageCorrect'] == 'number') 
        && (questionsStats[questionId]['percentageCorrect'] <101)) {
        let safeURL;
        const isURLsafe = true
        
        try {
          safeURL = new URL(quizObject.url);
          if (! ["http:", "https:"].includes(safeURL.protocol) ) {
            //throw new Error("Invalid protocol")
            isURLsafe = false
          }
        } catch(e) {
          console.log(`Bad URL ${e}`);
          isURLsafe = false
          return null
        }
        return  <>
            { isURLsafe &&
            <>
            {
                'explanation' in actualQuiz &&
                <p> {actualQuiz.explanation} </p>
            }
            <p>Quiz and explanation created with ChatGPT and based on <a href={quizObject.url} target="_blank">this article</a> </p>
            <p>{questionsStats[questionId]['percentageCorrect']? questionsStats[questionId]['percentageCorrect']: 100}% of the users answered correctly</p>
            </>
            }
        </>
        }
    else{
        return <></>
        }
    }

  const decideColor = (indexAnswerSelected, index,  indexCorrectAnswer) => {
    // do not highlight the button if it's not selected
    if (indexAnswerSelected === '' || indexAnswerSelected === null){
        return null
    }


    if (alreadySelectedAnAnswer && index === indexCorrectAnswer){
        return 'selected-answer'
    }

    if (indexAnswerSelected === index){
        if (indexCorrectAnswer === indexAnswerSelected){
            return 'selected-answer'
        } else {
            return 'selected-answer-wrong'
        }
    }


    return 'selection-made-other-buttons'



  }

  return (
    <div className='quiz-container'>
        <h2>{quizObject.question} </h2>
        <ul>
            {quizObject.choices.map( (answer, index) =>(
                <li
                key={index}
                onClick={!alreadySelectedAnAnswer? () => handleOnClickAnswer(index, questionsStats, questionId): undefined}
                className={decideColor(indexAnswerSelected, index, indexCorrectAnswer)}
                >
                    {answer}
                </li>
            ))
            }
        </ul>
        
        {conditionallyRenderStats(alreadySelectedAnAnswer)
        }
     </div>
  )
}

/*
        <MDBBtn>
        <i class="far fa-thumbs-up fa-2x"></i>
        </MDBBtn>
*/