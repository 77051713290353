import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { AuthContextProvider} from './context/AuthContext'
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
//import "@fortawesome/fontawesome-free/css/all.min.css";
import '@fortawesome/fontawesome-free/css/all.css';
import { ModalProvider } from './context/ModalContext';


if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(
  <ModalProvider>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </ModalProvider>,
  document.getElementById('root')
);
